// component
import PaymentIcon from '@mui/icons-material/Payment';
import KeyIcon from '@mui/icons-material/Key';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'playground',
    path: '/dashboard/playground',
    icon: <KeyboardIcon />,
  },
  {
    title: 'usage',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'api keys',
    path: '/dashboard/apikeys',
    icon: <KeyIcon />,
  },
  {
    title: 'upgrade',
    path: '/dashboard/pricing',
    // payment icon; 
    icon: <PaymentIcon />,
  },
  {
    title: 'contact',
    path: '/dashboard/contact',
    icon: <ContactMailIcon />,
  },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
