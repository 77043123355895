import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
// @mui
import {
  Checkbox,
  Modal,
  Container,
  Typography,
  TextField,
  Alert,
  Button,
  FormControlLabel,
} from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Logo from '../components/logo';

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  // [theme.breakpoints.up('md')]: {
  //   display: 'flex',
  // },
}));
const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      '10 users included',
      '2 GB of storage',
      'Help center access',
      'Email support',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '15',
    description: [
      '20 users included',
      '10 GB of storage',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '30',
    description: [
      '50 users included',
      '30 GB of storage',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage(props) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [comments, setComments] = React.useState('');

  const [error, setError] = React.useState(false);


  const sendContact = async () => {
    // send the firstName, lastname, email, company, and comments to POST /api/v1/email and open the modal 
    try {
      const response = await fetch('/api/v1/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email,
          company,
          comments

        }),
      });
      const data = await response.json();
      if (!data.error) {
        setOpen(true)
        setError(false)
      } else {
        setOpen(false)
        setError(true)
      }

    } catch (error) {
      setOpen(false)
      console.log(error)
      setError(true)

    }



  }

  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <StyledRoot>
        {!props.dashboard && <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />}
        <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Contact Us
          </Typography>
          {(props.dashboard || props.home) &&
            <Typography variant="" align="center" color="text.secondary" component="p">
              We are always here to help you. Please fill out the form below and we will get back to you as soon as possible.
              This form can also be used to join our paid beta program and report any bugs or issues you may have encountered.
            </Typography>
          }
          {!props.dashboard &&
            <Typography variant="" align="center" color="text.secondary" component="p">
              We do not support signups yet; if you think you should have access, please contact us below.<br />&nbsp;<br />
              We are always here to help you. Please fill out the form below and we will get back to you as soon as possible.
              This form can also be used to join our paid beta program and report any bugs or issues you may have encountered.
            </Typography>
          }
        </Container>
        {/* End hero unit */}
        <Container maxWidth="md" component="main">

          <Typography variant="h6" gutterBottom>
            Contact
          </Typography>

          {error && <Alert severity="error">Something went wrong, email not sent, try again!</Alert>}

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                id="firstName"
                name="firstName"
                label="First name"
                fullWidth
                autoComplete="given-name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                id="lastName"
                name="lastName"
                label="Last name"
                fullWidth
                autoComplete="given-name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                id="Company"
                name="Company"
                label="Company"
                fullWidth
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                name="email"
                label="Email"
                fullWidth
                autoComplete="current-email"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                id="outlined-multiline-static"
                label="Comments"
                multiline
                rows={7}
                fullWidth
                variant="outlined"
                placeholder='Questions or comments...'
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={sendContact}>Send</Button>
            </Grid>
          </Grid>

        </Container>
      </StyledRoot>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Email Sent!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your input has been sent to our team. We will get back to you as soon as possible.<br />
            Generally, we respond within 24 hours.
          </Typography>
        </Box>
      </Modal>

    </>
  );
}
