import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, Alert, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleClick = async () => {
    // sent the email and password to the /api/v1/login backend and check if it returns api_key & user_id, if yes, then set these in localStorage, if no , then setError(true)

    setError('');

    const response = await fetch('/api/v1/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    const data = await response.json();
    if (data.error) {
      setError(data.error);
    } else if (data.user_id && data.api_key) {
      localStorage.setItem('user_id', data.user_id);
      localStorage.setItem('api_key', data.api_key);
      navigate('/dashboard');
    } else {
      setError('Something went wrong, please try again later.')
    }

  };

  return (
    <>
      <Stack spacing={3}>
        {error && <Alert severity="error">{error}</Alert>}

        <TextField name="email"
          label="Email address"
          onChange={(event) => setEmail(event.target.value)}
        />

        <TextField
          name="password"
          label="Password"
          onChange={(event) => setPassword(event.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
