import { Helmet } from 'react-helmet-async';
import { filter, values } from 'lodash';
// @mui
import {
  Checkbox,
  Modal,
  Container,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  FormControlLabel,
} from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      '10 users included',
      '2 GB of storage',
      'Help center access',
      'Email support',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '15',
    description: [
      '20 users included',
      '10 GB of storage',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '30',
    description: [
      '50 users included',
      '30 GB of storage',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [human, setHuman] = React.useState(0);
  const [ai, setAI] = React.useState(0);
  const { hash } = useParams();
  const _hash = `https://filteroutai.com/validate/${hash}`
  const [text, setText] = React.useState('');
  const [error, setError] = React.useState(false);

  // useEffect to load hash from cache
  React.useEffect(() => {
    if (hash) {
      validate()

    }
  }, [hash])

  const validate = async () => {
    setIsLoading(true)
    try {
      const response = await fetch(`/api/v1/cache?hash=${hash}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.Human && data.ChatGPT) {
        setHuman(data.Human)
        setAI(data.ChatGPT);
        setText(data.content)
      } else {
        setError(true)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)

    }

    setOpen(true)
  }

  return (
    <>
      {isLoading && <CircularProgress style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }} />}
      <Helmet>
        <title>AI or Human</title>
      </Helmet>
      <Container disableGutters maxWidth="md" component="main" sx={{ pt: 2, pb: 6 }}>
        {error && <Alert severity="error">Something went wrong, please try again!</Alert>}

        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          AI or Human
        </Typography>
        <Typography variant="" align="center" color="text.secondary" component="p">
          Below are different options to validate if a document or text is written by a human or an AI. Currently we focus
          on NLP products from OpenAI like ChatGPT & GPT-3. Most commercial text generation systems are based on either of these currently.
          We are adding features as we go. If you have any suggestions, please let us know via the contact form.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Typography variant="h6" gutterBottom>
          Is it ChatGPT or human?
        </Typography>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <TextField
              id="outlined-multiline-static"
              label="Document"
              value={text}
              onChange={(event) => setText(event.target.value)}
              multiline
              rows={15}
              fullWidth
              variant="outlined"
              placeholder='Copy/Paste text to validate here...'
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              This is written by a human: {(human * 100).toFixed(2)} % certainty
            </Typography>
            <Typography variant="h6" gutterBottom>
              This is written by ChatGPT: {(ai * 100).toFixed(2)} % certainty
            </Typography>
            <Typography variant="h6" gutterBottom>
              Permalink: <a href={_hash}>{_hash}</a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" href="/login">Validate</Button>
          </Grid>
        </Grid>

      </Container>


    </>
  );
}
