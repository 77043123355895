import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import PricingPage from './pages/PricingPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import ContactPage from './pages/ContactPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ApiKeysPage from './pages/ApiKeysPage';
import RegisterPage from './pages/RegisterPage';
import PlaygroundPage from './pages/PlaygroundPage';
import PlaygroundPageOpen from './pages/PlaygroundPageOpen';
import ComingSoonPage from './pages/ComingSoonPage';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/playground" />, index: true },
        { path: 'playground', element: <PlaygroundPage /> },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'pricing', element: <PricingPage /> },
        { path: 'contact', element: <ContactPage dashboard="true" /> },
        { path: 'apikeys', element: <ApiKeysPage /> },

      ],
    },
    {
      path: 'pricing',
      element: <PricingPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'contact',
      element: <ContactPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'secretregister',
      element: <RegisterPage />,
    },
    {
      path: 'comingsoon',
      element: <ComingSoonPage />,
    },
    {
      path: 'validate/:hash',
      element: <PlaygroundPageOpen />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/comingsoon" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
