import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Stack } from '@mui/material';
import Logo from '../components/logo';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <>
      <Helmet>
        <title>Filter Out AI | Coming Soon</title>
      </Helmet>
      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
        <Container>
          <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
            <Typography variant="h3" paragraph>
              Coming Soon - Stop AI Plagiarism
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              We are working hard on stopping plagiarism with AI, including ChatGPT. We are currently launched in Beta
              for a limited audience. We will launch publicly in a little while.<br />
              Contact us for information or to be an early adopter.
            </Typography>

            <Box
              component="img"
              src="/assets/illustrations/timemachine.png"
              sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Button to="/contact" size="large" variant="contained" component={RouterLink}>
                Contact us
              </Button>
              &nbsp;&nbsp;
              <Button to="/login" size="large" variant="contained" component={RouterLink}>
                Go to Login
              </Button>
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
